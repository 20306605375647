import React, { useState } from 'react';
import styles from './contact.module.scss';
import Phone from '../svgs/phone.svg';
import Address from '../svgs/address.svg';
import Hour from '../svgs/hour.svg';

const Contact = () => {
  const [isApproved, setIsApproved] = useState(false);
  const RL = typeof window !== `undefined` ? require('react-leaflet') : null;
  const L = typeof window !== `undefined` ? require('leaflet') : null;
  if (L === null || RL === null) {
    return null;
  }

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  return (
    <>
      <div className={styles.data}>
        <Hour />
        <header className={styles.phoneHeader}>Godziny otwarcia: Pon. - Pt. 8:00 - 17:00</header>
        <Address />
        <header className={styles.phoneHeader}>Znajdziesz nas na ul. Powstańców 50, 31-422 Kraków</header>
        <Phone />
        <header className={styles.phoneHeader}>
          Skontaktuj się osobiście: <a href="tel:+48504396249">+48 504 396 249</a>
        </header>
      </div>
      <div className={styles.container}>
        <section className={styles.mapSection}>
          <RL.Map center={[50.098955, 19.986896]} zoom={16}>
            <RL.TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <RL.Marker position={[50.098955, 19.986896]}>
              <RL.Popup>BRAMINSTAL</RL.Popup>
            </RL.Marker>
          </RL.Map>
        </section>
        <section className={styles.formSection}>
          <h1 className={styles.formHeader}>Napisz wiadomość:</h1>
          <form className={styles.form} data-netlify="true">
            <input type="text" name="name" placeholder="Imię" />
            <input type="text" name="email" placeholder="Email" />
            <input type="text" name="phone" placeholder="Telefon" />
            <textarea name="message" placeholder="Wiadomość" />
            <div className={styles.approveContainer}>
              <input type="checkbox" id="zgoda" checked={isApproved} onChange={e => setIsApproved(e.target.checked)} />
              <label className={styles.approve} htmlFor="zgoda">
                Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w
                związku z realizacją zapytania. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia
                zapytania. Zostałem/am poinformowany/a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich
                poprawiania, żądania zaprzestania ich przetwarzania.
              </label>
            </div>
            <button className={styles.sendButton} disabled={!isApproved} type="submit">
              Wyślij
            </button>
          </form>
        </section>
      </div>
    </>
  );
};

export default Contact;
