import React, { useEffect } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Contact from '../components/contact';

const Kontakt = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <Contact />
    </Layout>
  );
};

export default Kontakt;
